import {Component, Inject, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {SessionService} from '../../services/session-service.service';
import {BehaviorSubject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {GroomBookingTermsDialogComponent} from '../groom-booking-terms-dialog/groom-booking-terms-dialog.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {format, parseISO} from 'date-fns';
import {environment} from '../../../environments/environment';
import {BookingItem} from '@appyvet/vetbooker-definitions/dist/bookings';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {BookingDepositDialogComponent} from "../booking-deposit-dialog/booking-deposit-dialog.component";
import {MatDialogConfig} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-booking-dialog',
  templateUrl: './confirm-booking-dialog.component.html',
  styleUrls: ['./confirm-booking-dialog.component.scss']
})
export class ConfirmBookingDialogComponent implements OnInit {

  isEdit: boolean;
  isGroomRoom = environment.GROOM;
  terms: boolean;
  error$ = new BehaviorSubject<string>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  patientBookingText: string;
  bookingItem: BookingItem;
  selectedResource: Resource;
  notes: string;
  editText: string;
  sms: boolean;
  smsNumbers: string[] = [];
  smsNumber: string;
  editId: string;
  formattedDate: string;
  notesForm: UntypedFormGroup;
  accountValidationMessages = {
    notes: [
      {type: 'required', message: 'Notes are required for this appointment type'},
      {type: 'length', message: 'Please keep the note to less than 150 characters'}
    ],
  };
  private clinicName: string;
  hideAllReasons: boolean;
  neverRequireReason: boolean;
  publicHolidayMessage: string;
  takeDeposit: boolean;
  depositAmount: number;
  clientHasDeposit: boolean;

  constructor(private sessionService: SessionService, private bookingService: BookingService,
              public dialogRef: MatDialogRef<ConfirmBookingDialogComponent>, public dialog: MatDialog,
              private gaService: GoogleAnalyticsService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.selectedResource = data.selectedResource;
    this.bookingItem = data.booking;
    this.publicHolidayMessage = data.publicHolidayMessage;
    this.takeDeposit = data.takeDeposit;
    this.depositAmount = data.depositAmount;
    this.clientHasDeposit = data.clientHasDeposit;

    if (this.bookingItem) {
      this.patientBookingText = bookingService.getPatientBookingText(this.bookingItem.selectedPatients);
      this.formattedDate = format(parseISO(this.bookingItem?.item.clinicDateTime.split('Z')[0]), 'eeee do MMMM y');
    }
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.clinicName = clientPatientDetails.themeSettings.customName || 'VetBooker';
      this.sms = clientPatientDetails.appointmentSettings.sms;
      this.hideAllReasons = clientPatientDetails.appointmentSettings.hideReason;
      this.neverRequireReason = clientPatientDetails.appointmentSettings.neverRequireReason;
      this.smsNumbers.push('Select number for SMS confirmation');
      // Ensure only a single SMS number in the list
      clientPatientDetails.contacts.forEach(contact => {
        if (contact.typeCode === 3) {
          if (!this.isGroomRoom || this.smsNumbers.length === 1) {
            this.smsNumbers.push(contact.number);
          }
        }
      });
      if (this.smsNumbers.length === 1) {
        this.sms = false;
      }
      this.smsNumber = this.smsNumbers[0];
    });
    this.bookingService.editingAppointment$.subscribe(editingAppt => {
      if (editingAppt) {
        this.isEdit = true;
        this.editText = this.bookingService.getAmendApptText(this.bookingItem, this.selectedResource);
      }
    });
    const notesGroupsItems: any = {};
    for (let i = 0; i < this.bookingItem.selectedPatients.length; i++) {
      const validators = [];
      if (this.bookingItem.selectedPatients[i].selectedAppointmentType.requireReason) {
        validators.push(Validators.required);
      }
      notesGroupsItems['notes' + i] = new UntypedFormControl(this.bookingItem.selectedPatients[i].notes, validators);
    }
    this.notesForm = new UntypedFormGroup(notesGroupsItems);
  }

  doBookApt(): void {
    this.bookingService.bookAppt(this.bookingItem).subscribe(done => {
      this.dialogRef.close({success: true, message: 'Successfully booked your appointment'});
    }, () => {
      this.error$.next(
        'Unfortunately we are unable to book the selected time it has now been taken by another ' + (this.isGroomRoom ? 'customer' : 'client') +
        '. Please select an alternative appointment slot or call the ' + (this.isGroomRoom ? 'salon' : 'clinic') + ' directly.');
    }).add(() => {
      this.loading$.next(false);
    });
  }

  book() {
    const patientsWithErrors = [];
    for (let i = 0; i < this.bookingItem.selectedPatients.length; i++) {
      const patient = this.bookingItem.selectedPatients[i];
      const notesIndex = 'notes' + i;
      patient.notes = this.notesForm.get(notesIndex).value;
      if (!patient.selectedAppointmentType.hideReason && patient.selectedAppointmentType.requireReason &&
        (!patient.notes || patient.notes.length === 0)) {
        this.notesForm.get(notesIndex).markAsTouched();
        this.notesForm.get(notesIndex).markAsDirty();
        patientsWithErrors.push(patient);
      }
    }

    let error = '';
    for (let i = 0; i < patientsWithErrors.length; i++) {
      const patient = patientsWithErrors[i];
      if (i === 0) {
        error += 'You must include appointment notes for ';
      } else if (patientsWithErrors.length > 1 && i < patientsWithErrors.length - 1) {
        error += ', ';
      } else if (i === patientsWithErrors.length - 1) {
        error += ' and for ';
      }
      error += patient.patient.patientName;
    }

    if (patientsWithErrors.length === 0) {
      // This code is here to try and prevent double clicks/duplication.
      if (!this.bookingService.sendingRequest$.getValue()) {
        this.bookingItem.smsNumber = this.smsNumber;
        this.bookingItem.device = 'web';

        for (let i = 0; i < this.bookingItem.selectedPatients.length; i++) {
          this.bookingItem.selectedPatients[i].notes = this.notesForm.get('notes' + i).value;
        }

        if (!this.isEdit) {
          if (this.takeDeposit && !this.clientHasDeposit) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'no-padding-dialog';
            dialogConfig.maxWidth = '90vw';

            dialogConfig.data = {
              depositAmount: this.depositAmount,
            };

            const depositDialogRef = this.dialog.open(BookingDepositDialogComponent, dialogConfig);
            depositDialogRef.afterClosed().subscribe(result => {
              if (result && result.paid === true) {
                this.doBookApt();
              }
            });
          } else {
            this.doBookApt();
          }
          // this.doBookApt();
        } else {
          this.bookingService.amendAppt(this.bookingItem).subscribe(() => {
            this.gaService.event(
              'Book Amend Appointment',
              'Appointment Booker',
              this.clinicName + ' - ' + this.bookingItem.selectedPatients[0].selectedAppointmentType.displayName,
            );
            this.dialogRef.close({success: true, message: 'Successfully updated your appointment'});
          }, error2 => {
            if (error2.message) {
              this.error$.next(error2.message.msg);
            } else {
              this.gaService.event(
                'Book Amend Appointment Error',
                'Appointment Booker',
                this.clinicName,
              );
              this.error$.next(
                'Unfortunately we are unable to change your appointment at this time. One possible reason ' +
                'is that the slot may have now been booked. Please try again at a different time or call the ' + (this.isGroomRoom
                                                                                                                  ? 'salon.'
                                                                                                                  : 'clinic.'));
            }
          }).add(() => {
            this.loading$.next(false);
            this.dialogRef.close(true);
          });
        }
      }
    } else {
      this.notesForm.markAsDirty();
    }
  }

  showAgreement() {
    this.dialog.open(GroomBookingTermsDialogComponent, {panelClass: ['no-padding-dialog', 'no-scroll-dialog']});
  }
}
